.App {
  padding: 1rem;
  background-color: black;
  color: white;
  min-height: 100vh;
}

@media only screen and (min-width: 701px) {
  .iframeContainer {
    height: 94vh;
    width: 90%;
    float: right;
    border: none;
  }
  .settings-btn {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .iframeContainer,
  .settings-panel.active {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    z-index: 10;
  }
  .iframeContainer.inactive,
  .settings-panel {
    z-index: 1;
    background: black;
  }
  .settings-btn {
    position: fixed;
    top: 5vh;
    right: 0;
    padding: 10px;
    z-index: 11;
    color: white;
    background: #ff9500;
  }
}

.main-heading {
  font-size: 3.4rem;
  font-weight: 300;
}

.main-col {
  max-height: 95vh;
  overflow-y: scroll;
  background: #0d0d0d;
  max-width: 95%;
  border-radius: 11px;
}

.main-col::-webkit-scrollbar {
  display: none;
}

.main-heading::before {
  content: "|";
  color: #ff9500;
  font-weight: 200;
  font-size: 5rem;
}

.iframe-frame {
  /* background-image: url("./img/frame.png"); */
}

select,
input {
  min-width: 10rem;
  background: #383839;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.picker {
  margin: 1rem 0;
}

.react-colorful {
  height: 150px !important;
  width: 150px !important;
  margin: 1.2rem auto !important;
}
.react-colorful__saturation {
  border-radius: 4px 4px 0 0;
}
.react-colorful__hue {
  height: 40px;
  border-radius: 0 0 4px 4px;
}
.react-colorful__hue-pointer {
  width: 12px;
  height: inherit;
  border-radius: 0;
}

.mb-0-4rem {
  margin-bottom: 0.4rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.m-1rem {
  margin: 1rem;
}
.mt-1rem {
  margin-top: 1rem;
}

.colorpicker-container {
  max-width: 100%;
  margin: 0.5rem auto;
  width: 17vw;
  background: #1c1c1d;
  padding: 1.8rem 0.4rem;
  border-radius: 11px;
}

.colorpicker-container > input {
  text-align: center;
  width: 150px;
  margin-bottom: 0.3rem;
}

.react-tabs {
  display: flex;
  margin-left: 0.1rem;
  width: 100%;
  height: 100%;
  border: 1px solid #3c3e43;
  color: white;
  background: #1c1d1f;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
}

.react-tabs__tab {
  height: 2.5rem;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #fff;
  border-radius: 4px !important;
}

.react-tabs__tab::after {
  display: none !important;
}

.react-tabs__tab--selected {
  background: #929292;
  border-color: #929292;
  border-left: 4px solid #6eb800;
  color: white;
}

.react-tabs__tab-panel {
  display: none;
  width: 100%;
  padding: 0.1rem 1rem;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}

.fontname {
  width: 11vw;
  margin: auto;
  text-align: center;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  cursor: pointer;
  /* background: rgb(255 149 0 / 20%);*/
}
.font-selected {
  background: rgba(255, 149, 0, 0.2);
}

.fontname-container {
  padding: 1rem;
}

button {
  background: #ff9500;
  border: none;
  min-width: 14rem;
  width: 70%;
  padding: 1rem 0;
  border-radius: 13px;
  box-shadow: 0px 7px 24px rgba(255, 149, 0, 0.35);
}

.homebg-ops {
  padding: 0.5rem 0;
  margin: 1rem 0;
}

.chk-box {
  /* display: flex; */
  appearance: none;
  background: linear-gradient(#777, #888);
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2) inset,
    0 1px 1px 1px rgba(255, 255, 255, 0.2);

  display: inline;
  margin-right: 1rem;
}
.chk-box::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}
.chk-box::after:hover {
  color: #ffcc00;
}
.chk-box:checked::after {
  color: #ff9500;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.2);
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.cursor-pointer {
  cursor: pointer;
}

.small-btn {
  height: 2rem !important;
  padding: 0.1rem !important;
  min-width: 4rem !important;
  width: 9rem !important;
  margin: 0.9rem 0 !important;
  border-radius: 5px !important;
}
